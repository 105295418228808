<template>
  <nav
    class="navbar navbar-main navbar-expand-lg px-0 py-3 shadow-none"
    id="navbarBlur"
    navbar-scroll="true"
    style="border-bottom: 1px solid #ddd"
  >
    <div class="container-fluid py-1 px-3">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
          <li class="breadcrumb-item text-sm">
            <span class="opacity-5 text-dark">Pages</span>
          </li>
          <li
            class="breadcrumb-item text-sm text-dark active"
            aria-current="page"
          >
            {{ currentRouteName }}
          </li>
        </ol>
      </nav>
      <div
        class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
        id="navbar"
      >
        <ul
          class="
            ms-md-auto
            pe-md-3
            d-flex
            align-items-center
            navbar-nav
            justify-content-end
          "
        >
          <li class="nav-item dropdown pe-2 d-flex align-items-center">
            <a
              href="javascript:;"
              class="nav-link text-body p-0"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa fa-user me-sm-1"></i>
              {{ admin.name }}
            </a>
            <ul
              class="dropdown-menu dropdown-menu-end px-2 py-3 me-sm-n4"
              aria-labelledby="dropdownMenuButton"
              style="border: 1px solid #ddd"
            >
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="d-flex py-1">
                    <div class="my-auto">
                      <i class="fa fa-users me-sm-1"></i>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="text-sm font-weight-normal mb-0">
                        User management
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
              <li class="mb-2">
                <a
                  class="dropdown-item border-radius-md"
                  href="javascript:;"
                  @click="logout"
                >
                  <div class="d-flex py-1">
                    <div class="my-auto">
                      <i class="fa fa-unlock me-sm-1"></i>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="text-sm font-weight-normal mb-0">Logout</h6>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      admin: (state) => state.adminController.admin,
    }),
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    logout() {
      this.$session.delete();
      window.location.href = "/";
    },
  },
};
</script>

<style>
</style>