<template>
  <div class="row">
    <div class="col-6 d-flex align-items-center">
      <h6 class="mb-0">Retailers</h6>
    </div>
    <div class="col-6 text-end">
      <button
        class="btn bg-gradient-dark mb-0"
        @click="openSidebar"
      >
        <i class="fas fa-plus me-1"></i> Add Retailer
      </button>
    </div>

    <retailer-detail />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import retailerDetail from "./sidebar/retailerDetail.vue";

export default {
  components: {
    retailerDetail,
  },
  methods: {
    ...mapMutations({
      toggleShowRetailerSidebar:
        "retailerController/TOGGLE_ShowRetailerSidebar",
      ResetRetailer: "retailerController/SELECT_Retailer",
    }),
    openSidebar() {
      this.toggleShowRetailerSidebar(true);
      this.ResetRetailer({});
      console.log(this.selectedRetailer);
    },
  },
};
</script>

<style>
</style>