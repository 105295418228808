<template>
  <div>
    <retailer v-if="!showSubRetailer" />
    <subRetailer v-if="showSubRetailer" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import retailer from "./components/retailer/index.vue";
import subRetailer from "./components/subretailer/index.vue";

export default {
  components: {
    retailer,
    subRetailer,
  },
  computed: {
    ...mapState({
      showSubRetailer: (state) => state.retailerController.showSubRetailer,
    }),
  },
};
</script>

<style>
</style>