const sessionName = '_ssid'

const Session = {
    token() {
        var tokenCookie = '';
        let name = sessionName + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                tokenCookie = c.substring(name.length, c.length);
            }
        }

        if (tokenCookie != '') {
            return localStorage.getItem(sessionName);
        } else {
            return "";
        }
    },

    set(value) {
        var cookieValue = btoa(sessionName);
        var now = new Date();
        var time = now.getTime();
        time += 3600 * 1000;
        now.setTime(time);

        var expires = "expires=" + now;
        document.cookie = `${sessionName}=${cookieValue};${expires};path=/;`;
        localStorage.setItem(sessionName, value);
    },
    delete() {
        document.cookie = `${sessionName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        localStorage.clear();
    },
}

export default Session;