<template>
  <div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-header pb-0">
          <div class="row">
            <div class="col-6">
              <h6 class="mb-0">Products</h6>
            </div>
          </div>
        </div>
        <hr class="mx-3" style="border-top: 1px solid !important" />

        <product-header />
        <hr class="horizontal dark mt-3">
        <product-list />
      </div>
    </div>
  </div>
</template>

<script>
import productHeader from "./components/header.vue";
import productList from "./components/productList.vue";

export default {
  components: {
    "product-header": productHeader,
    "product-list": productList,
  },
};
</script>

<style>
</style>