import axios from "axios";
import Session from './../session'

var instance = axios.create({
    baseURL: window.location.origin,
});

export default {
    namespaced: true,
    state: {
        /// retailer
        submitting: false,
        loading: false,
        retailers: [],
        filteredRetailers: [],
        selectedRetailer: {},
        showRetailerSidebar: false,
        filter: {
            keyword: ''
        },

        /// sub retailer
        subRetailerLoading: false,
        subRetailerSubmitting: false,
        subRetailers: [],
        filteredSubRetailers: [],
        selectedSubRetailer: {},
        showSubRetailerSidebar: false,
        showSubRetailerBalanceSidebar: false,
        subRetailerFilter: {
            keyword: ''
        },

        /// sub retailer users
        subRetailerUsersLoading: false,
        subRetailerUsersSubmitting: false,
        subRetailerUsers: [],
        selectedSubRetailerUser: {},
        showSubRetailerUserSidebar: false,
        showSubRetailerUserDetailSidebar: false,

        /// transactions
        transactions: [],
        transactionsLoading: false,
        transactionsExportLoading: false,
        transactionsCurrentPage: 1,
        transactionsFilter: {
            dateRange: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(),]
        },

        /// load subscriber
        showLoadSubscriberSidebar: false,
        loadSubscriberSubmitting: false,
        loadSubscriber: {
            category: "0",
            elpKeyword: "0",
            amount: 0,
            mobileNumbers: ""
        }
    },
    getters: {
        selectedRetailer(state) {
            return state.selectedRetailer;
        },
        selectedSubRetailer(state) {
            return state.selectedSubRetailer;
        },
        selectedSubRetailerUser(state) {
            return state.selectedSubRetailerUser;
        },
        transactionsFilter(state) {
            let yearFrom = new Intl.DateTimeFormat("en", { year: "numeric" }).format(state.transactionsFilter.dateRange[0]);
            let monthFrom = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(state.transactionsFilter.dateRange[0]);
            let dayFrom = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(state.transactionsFilter.dateRange[0]);
            var dateFrom = `${yearFrom}-${monthFrom}-${dayFrom}`;

            let yearTo = new Intl.DateTimeFormat("en", { year: "numeric" }).format(state.transactionsFilter.dateRange[1]);
            let monthTo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(state.transactionsFilter.dateRange[1]);
            let dayTo = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(state.transactionsFilter.dateRange[1]);
            var dateTo = `${yearTo}-${monthTo}-${dayTo}`;

            return {
                dateFrom: dateFrom,
                dateTo: dateTo
            }
        },
        loadSubscriber(state) {
            return state.loadSubscriber;
        }
    },
    mutations: {
        SET_Transactions(state, value) {
            state.transactions = value;
            state.transactionsCurrentPage = 1;
        },
        SET_TransactionsLoading(state, value) {
            state.transactionsLoading = value;
        },
        SET_TransactionsExportLoading(state, value) {
            state.transactionsExportLoading = value;
        },
        SET_TransactionsCurrentPage(state, value) {
            state.transactionsCurrentPage = value;
        },
        SET_LoadSubscriberSubmitting(state, value) {
            state.loadSubscriberSubmitting = value;
        },
        TOGGLE_ShowLoadSubscriberSidebar(state, value) {
            state.showLoadSubscriberSidebar = value;
            state.loadSubscriber = {
                category: "0",
                elpKeyword: "0",
                amount: 0,
                mobileNumbers: ""
            }
        },
        TOGGLE_ShowRetailerSidebar(state, value) {
            state.showRetailerSidebar = value
        },
        SET_Submitting(state, value) {
            state.submitting = value;
        },
        SET_Loading(state, value) {
            state.loading = value;
        },
        SET_SubRetailerLoading(state, value) {
            state.subRetailerLoading = value;
        },
        SET_SubRetailerUsersLoading(state, value) {
            state.subRetailerUsersLoading = value;
        },
        SET_Retailers(state, payload) {
            state.retailers = payload;
        },
        SET_ShowSubRetailer(state, value) {
            state.showSubRetailer = value;
        },
        SET_SubRetailers(state, payload) {
            state.subRetailers = payload;
        },
        SET_SubRetailerUsers(state, payload) {
            state.subRetailerUsers = payload;
        },
        SELECT_Retailer(state, payload) {
            const returnedTarget = Object.assign({}, payload);
            state.selectedRetailer = returnedTarget;
        },
        FILTER_Retailers(state) {
            state.filteredRetailers = state.retailers;
        },
        FILTER_SubRetailers(state) {
            var keyword = state.subRetailerFilter.keyword;

            state.filteredSubRetailers = state.subRetailers.filter(m => m.name.includes(keyword));
        },
        SET_ShowSubRetailerSidebar(state, value) {
            state.showSubRetailerSidebar = value;
        },
        SET_ShowSubRetailerBalanceSidebar(state, value) {
            state.showSubRetailerBalanceSidebar = value;
        },
        SET_ShowSubRetailerUserSidebar(state, value) {
            state.showSubRetailerUserSidebar = value;
        },
        SET_ShowSubRetailerUserDetailSidebar(state, value) {
            state.showSubRetailerUserDetailSidebar = value;
        },
        SET_SubRetailerSubmitting(state, value) {
            state.subRetailerSubmitting = value;
        },
        SET_SubRetailerUsersSubmitting(state, value) {
            state.subRetailerUsersSubmitting = value;
        },
        SELECT_SubRetailer(state, payload) {
            if (payload == null) {
                state.selectedSubRetailer = {
                    subRetailerID: 0,
                    retailerID: state.selectedRetailer.retailerID,
                    name: "",
                    branch: "",
                    discountRate: 0
                }
            } else {
                const returnedTarget = Object.assign({}, payload);
                state.selectedSubRetailer = returnedTarget;
            }
        },
        SELECT_SubRetailerUser(state, payload) {
            if (payload == null) {
                state.selectedSubRetailerUser = {
                    subRetailerUserID: 0,
                    subRetailerID: state.selectedSubRetailer.subRetailerID,
                    name: "",
                    username: "",
                    email: ""
                }
            } else {
                const returnedTarget = Object.assign({}, payload);
                state.selectedSubRetailerUser = returnedTarget;
            }
        }
    },
    actions: {
        GetRetailers({ commit }) {
            commit('SET_Loading', true);
            commit('SET_Retailers', []);
            commit('FILTER_Retailers');

            return new Promise((resolve, reject) => {
                instance
                    .get("api/retailer", {
                        headers: {
                            Authorization: "Bearer " + Session.token(),
                        }
                    })
                    .then(async function (response) {
                        commit('SET_Retailers', response.data);
                        commit('FILTER_Retailers');
                        commit('SET_Loading', false);
                        resolve(true);
                    })
                    .catch(function (error) {
                        commit('SET_Loading', false);
                        reject(error.response);
                    });
            });
        },
        AddRetailer({ commit, getters }) {
            commit('SET_Submitting', true);

            return new Promise((resolve, reject) => {
                instance
                    .post("api/retailer", getters.selectedRetailer, {
                        headers: {
                            Authorization: "Bearer " + Session.token(),
                        }
                    })
                    .then(async function (response) {
                        commit('SET_Submitting', false);
                        resolve(true);
                    })
                    .catch(function (error) {
                        commit('SET_Submitting', false);
                        reject(error.response);
                    });
            });
        },
        UpdateRetailer({ commit, getters }) {
            commit('SET_Submitting', true);

            return new Promise((resolve, reject) => {
                instance
                    .put(`api/retailer/${getters.selectedRetailer.retailerID}`, getters.selectedRetailer, {
                        headers: {
                            Authorization: "Bearer " + Session.token(),
                        }
                    })
                    .then(async function (response) {
                        commit('SET_Submitting', false);
                        resolve(true);
                    })
                    .catch(function (error) {
                        commit('SET_Submitting', false);
                        reject(error.response);
                    });
            });
        },
        GetSubRetailers({ commit, getters }) {
            commit('SET_ShowSubRetailer', false);
            commit('SET_SubRetailerLoading', true);
            commit('SET_SubRetailers', []);
            commit('FILTER_SubRetailers');

            return new Promise((resolve, reject) => {
                instance
                    .get(`api/subretailer/${getters.selectedRetailer.retailerID}`, {
                        headers: {
                            Authorization: "Bearer " + Session.token(),
                        }
                    })
                    .then(async function (response) {
                        commit('SET_SubRetailers', response.data);
                        commit('FILTER_SubRetailers');
                        commit('SET_SubRetailerLoading', false);
                        commit('SET_ShowSubRetailer', true);
                        resolve(true);
                    })
                    .catch(function (error) {
                        commit('SET_SubRetailerLoading', false);
                        reject(error.response);
                    });
            });
        },
        AddSubRetailer({ commit, getters }) {
            commit('SET_SubRetailerSubmitting', true);
            var retailerID = getters.selectedRetailer.retailerID;
            var subRetailer = getters.selectedSubRetailer;

            return new Promise((resolve, reject) => {
                instance
                    .post(`api/subretailer/${retailerID}`, subRetailer, {
                        headers: {
                            Authorization: "Bearer " + Session.token(),
                        }
                    })
                    .then(async function (response) {
                        commit('SET_SubRetailerSubmitting', false);
                        resolve(true);
                    })
                    .catch(function (error) {
                        commit('SET_SubRetailerSubmitting', false);
                        reject(error.response);
                    });
            });
        },
        UpdateSubRetailer({ commit, getters }) {
            commit('SET_SubRetailerSubmitting', true);
            var retailerID = getters.selectedRetailer.retailerID;
            var subRetailer = getters.selectedSubRetailer;

            return new Promise((resolve, reject) => {
                instance
                    .put(`api/subretailer/${retailerID}/${subRetailer.subRetailerID}`, subRetailer, {
                        headers: {
                            Authorization: "Bearer " + Session.token(),
                        }
                    })
                    .then(async function (response) {
                        commit('SET_SubRetailerSubmitting', false);
                        resolve(true);
                    })
                    .catch(function (error) {
                        commit('SET_SubRetailerSubmitting', false);
                        reject(error.response);
                    });
            });
        },
        UpdateSubRetailerBalance({ commit, getters }, amount) {
            commit('SET_SubRetailerSubmitting', true);
            var retailerID = getters.selectedRetailer.retailerID;
            var subRetailer = getters.selectedSubRetailer;

            return new Promise((resolve, reject) => {
                instance
                    .post(`api/retailer/${retailerID}/load/transfer/${subRetailer.subRetailerID}`, { amount }, {
                        headers: {
                            Authorization: "Bearer " + Session.token(),
                        }
                    })
                    .then(async function (response) {
                        commit('SET_SubRetailerSubmitting', false);
                        resolve(true);
                    })
                    .catch(function (error) {
                        commit('SET_SubRetailerSubmitting', false);
                        reject(error.response);
                    });
            });
        },
        GetSubRetailerUsers({ commit, getters }) {
            commit('SET_SubRetailerUsersLoading', true);
            commit('SET_SubRetailerUsers', []);
            var subRetailer = getters.selectedSubRetailer;

            return new Promise((resolve, reject) => {
                instance
                    .get(`api/subretailer/${subRetailer.subRetailerID}/user`, {
                        headers: {
                            Authorization: "Bearer " + Session.token(),
                        }
                    })
                    .then(async function (response) {
                        commit('SET_SubRetailerUsers', response.data);
                        commit('SET_SubRetailerUsersLoading', false);
                        resolve(true);
                    })
                    .catch(function (error) {
                        commit('SET_SubRetailerUsersLoading', false);
                        reject(error.response);
                    });
            });
        },
        AddSubRetailerUsers({ commit, getters }) {
            commit('SET_SubRetailerUsersSubmitting', true);
            var subRetailer = getters.selectedSubRetailer;
            var subRetailerUser = getters.selectedSubRetailerUser;

            return new Promise((resolve, reject) => {
                instance
                    .post(`api/subretailer/${subRetailer.subRetailerID}/user`, subRetailerUser, {
                        headers: {
                            Authorization: "Bearer " + Session.token(),
                        }
                    })
                    .then(async function (response) {
                        commit('SET_SubRetailerUsersSubmitting', false);
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        commit('SET_SubRetailerUsersSubmitting', false);
                        reject(error.response);
                    });
            });
        },
        GetTransactions({ commit, getters }) {
            commit('SET_TransactionsLoading', true);
            commit('SET_Transactions', []);
            var transactionsFilter = getters.transactionsFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get(`api/subretailer/user/log/trail?dateFrom=${transactionsFilter.dateFrom}&dateTo=${transactionsFilter.dateTo}`, {
                        headers: {
                            Authorization: "Bearer " + Session.token(),
                        }
                    })
                    .then(async function (response) {
                        commit('SET_Transactions', response.data);
                        commit('SET_TransactionsLoading', false);
                        resolve(true);
                    })
                    .catch(function (error) {
                        commit('SET_TransactionsLoading', false);
                        reject(error.response);
                    });
            });
        },
        ExportTransactions({ commit, getters }) {
            commit('SET_TransactionsExportLoading', true);
            var transactionsFilter = getters.transactionsFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get(`api/subretailer/user/log/trail/export?dateFrom=${transactionsFilter.dateFrom}&dateTo=${transactionsFilter.dateTo}`, {
                        responseType: 'blob',
                        headers: {
                            Authorization: "Bearer " + Session.token(),
                        }
                    })
                    .then(async function (response) {
                        var filename = response.headers['content-disposition'].split("; ")[1].split('=')[1].replace(/"/g, '');
                        const url = URL.createObjectURL(new Blob([response.data], {
                            type: 'application/octet-stream'
                        }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();

                        commit('SET_TransactionsExportLoading', false);
                        resolve(true);
                    })
                    .catch(function (error) {
                        commit('SET_TransactionsExportLoading', false);
                        reject(error.response);
                    });
            });
        },
        DirectLoadSubscriber({ commit, getters }) {
            commit('SET_LoadSubscriberSubmitting', true);
            var retailerID = getters.selectedRetailer.retailerID;
            var loadSubscriber = getters.loadSubscriber;

            return new Promise((resolve, reject) => {
                instance
                    .post(`api/retailer/${retailerID}/load/subscriber`, loadSubscriber, {
                        headers: {
                            Authorization: "Bearer " + Session.token(),
                        }
                    })
                    .then(async function (response) {
                        commit('SET_LoadSubscriberSubmitting', false);
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        commit('SET_LoadSubscriberSubmitting', false);
                        reject(error.response);
                    });
            });
        },
    },
}