<template>
  <sidebar
    :title="
      selectedSubRetailer.subRetailerID != 0
        ? `Edit Sub Retailer #${selectedSubRetailer.subRetailerID}`
        : 'Add Sub Retailer'
    "
    :show="showSubRetailerSidebar"
    @on-close="close"
    size="sm"
  >
    <template v-slot:body>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Name</label>
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                v-model="selectedSubRetailer.name"
                :disabled="subRetailerSubmitting"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Branch</label>
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                v-model="selectedSubRetailer.branch"
                :disabled="subRetailerSubmitting"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Discount (%)</label>
            <div class="mb-3">
              <input
                type="number"
                class="form-control"
                v-model="selectedSubRetailer.discountRate"
                :disabled="subRetailerSubmitting"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="text-center">
        <button
          type="button"
          class="btn bg-info bg-violet text-white w-100 mt-4 mb-0"
          :disabled="subRetailerSubmitting"
          @click="submitSubRetailer"
        >
          <i
            class="fa-solid fa-spinner fa-spin-pulse mx-1"
            v-if="subRetailerSubmitting"
          ></i>
          Submit
        </button>
      </div>
    </template>
  </sidebar>
</template>

<script>
import sidebar from "../../../../../components/sidebar.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    sidebar,
  },
  computed: {
    ...mapState({
      subRetailerSubmitting: (state) =>
        state.retailerController.subRetailerSubmitting,
      showSubRetailerSidebar: (state) =>
        state.retailerController.showSubRetailerSidebar,
      selectedSubRetailer: (state) =>
        state.retailerController.selectedSubRetailer,
    }),
  },
  methods: {
    ...mapActions({
      getSubRetailers: "retailerController/GetSubRetailers",
      addSubRetailer: "retailerController/AddSubRetailer",
      updateSubRetailer: "retailerController/UpdateSubRetailer",
    }),
    ...mapMutations({
      setShowSubRetailerSidebar:
        "retailerController/SET_ShowSubRetailerSidebar",
      selectSubRetailer: "retailerController/SELECT_SubRetailer",
    }),
    close() {
      this.selectSubRetailer(null);
      this.setShowSubRetailerSidebar(false);
    },
    async submitSubRetailer() {
      if (this.selectedSubRetailer.subRetailerID != 0) {
        var result = await this.updateSubRetailer().catch((error) => {
          console.log(error);
        });
        if (result) {
          alert(
            `Sub Retailer #${this.selectedSubRetailer.subRetailerID} successfully updated.`
          );
          await this.getSubRetailers();
          this.close();
        }
      } else {
        var result = await this.addSubRetailer().catch((error) => {
          console.log(error);
        });
        if (result) {
          alert("Sub Retailer successfully added.");
          await this.getSubRetailers();
          this.close();
        }
      }
    },
  },
};
</script>

<style>
</style>