<template>
  <div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-header pb-0">
          <sub-retailer-nav />
        </div>
        <hr class="horizontal dark" />
        <div class="card-header py-0">
          <sub-retailer-header />
        </div>
        <hr class="horizontal dark mt-3" />
        <div class="card-body px-0 pt-0 pb-2">
          <sub-retailer-list />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import subRetailerList from "./subRetailerList.vue";
import header from "./header.vue";
import nav from "./nav.vue";

export default {
  components: {
    "sub-retailer-header": header,
    "sub-retailer-nav": nav,
    "sub-retailer-list": subRetailerList,
  },
  methods: {
    ...mapMutations({
      showSubRetailer: "retailerController/SET_ShowSubRetailer",
    }),
  },
};
</script>

<style scoped``>
.fa-plus-circle {
  color: #17ad37;
  cursor: pointer;
}
</style>