<template>
  <div class="table-responsive p-0">
    <table class="table align-items-center mb-0">
      <thead>
        <tr>
          <th
            class="
              text-uppercase text-secondary text-xxs
              font-weight-bolder
              opacity-7
            "
          >
            Name
          </th>
          <th
            class="
              text-uppercase text-secondary text-xxs
              font-weight-bolder
              opacity-7
              ps-2
            "
          >
            Remaining Balance
          </th>
          <th
            class="
              text-center text-uppercase text-secondary text-xxs
              font-weight-bolder
              opacity-7
            "
          >
            Discount (%)
          </th>
          <th
            class="
              text-center text-uppercase text-secondary text-xxs
              font-weight-bolder
              opacity-7
            "
          >
            Date Created
          </th>
          <th class="text-secondary opacity-7"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="subRetailerLoading">
          <td colspan="5">
            <div class="text-center">
              <Vue3Lottie
                :animationData="searchingJSON"
                class="height-150 py-2"
              />
            </div>
          </td>
        </tr>
        <tr v-if="!subRetailerLoading && subRetailers.length == 0">
          <td colspan="5">
            <div class="text-center">
              <Vue3Lottie :animationData="noDataJSON" class="height-150 py-2" />
              <p class="text-xs font-weight-bold">No Data Found</p>
            </div>
          </td>
        </tr>
        <tr v-for="(subRetailer, i) in subRetailers" :key="i">
          <td>
            <div class="d-flex px-2 py-1">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-sm">{{ subRetailer.name }}</h6>
                <p class="text-xs text-secondary mb-0">
                  {{ subRetailer.branch }}
                </p>
              </div>
            </div>
          </td>
          <td>
            <p class="text-xs font-weight-bold mb-0">
              <i
                class="fa-solid fa-plus-circle ms-2"
                @click="showSubRetailerBalance(subRetailer)"
              ></i>
              {{ subRetailer.remainingBalance.toLocaleString("en") }}
            </p>
          </td>
          <td class="align-middle text-center text-sm">
            <span class="badge badge-sm bg-gradient-success"
              >{{ subRetailer.discountRate }} %</span
            >
          </td>
          <td class="align-middle text-center">
            <span class="text-secondary text-xs font-weight-bold">
              {{ $format.FormatFullDateTime(subRetailer.timestamp) }}
            </span>
          </td>
          <td class="align-middle">
            <a
              href="javascript:;"
              class="text-secondary font-weight-bold text-xs"
              data-toggle="tooltip"
              data-original-title="Edit user"
            >
              <i
                class="fas fa-users text-dark cursor-pointer mx-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="User Access"
                @click="showSubRetailerUserSidebar(subRetailer)"
              ></i>
            </a>
            <a
              href="javascript:;"
              class="text-secondary font-weight-bold text-xs"
              data-toggle="tooltip"
              data-original-title="Edit user"
            >
              <i
                class="fas fa-pencil-alt text-dark cursor-pointer mx-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Edit"
                @click="showSubRetailerDetail(subRetailer)"
              ></i>
            </a>
            <!-- <a
              href="javascript:;"
              class="text-secondary font-weight-bold text-xs"
              data-toggle="tooltip"
              data-original-title="Edit user"
            >
              <i
                class="fas fa-trash text-dark cursor-pointer mx-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Delete"
                @click="notImplemented"
              ></i>
            </a> -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import noDataJSON from "./../../../../assets/no-data.json";
import searchingJSON from "./../../../../assets/searching.json";

export default {
  data() {
    return {
      noDataJSON,
      searchingJSON,
    };
  },
  computed: {
    ...mapState({
      subRetailers: (state) => state.retailerController.filteredSubRetailers,
      subRetailerLoading: (state) =>
        state.retailerController.subRetailerLoading,
    }),
  },
  methods: {
    ...mapMutations({
      setShowSubRetailerBalanceSidebar:
        "retailerController/SET_ShowSubRetailerBalanceSidebar",
      setShowSubRetailerUserSidebar:
        "retailerController/SET_ShowSubRetailerUserSidebar",
      setShowSubRetailerSidebar:
        "retailerController/SET_ShowSubRetailerSidebar",
      selectSubRetailer: "retailerController/SELECT_SubRetailer",
    }),
    ...mapActions({
      getSubRetailerUsers: "retailerController/GetSubRetailerUsers",
    }),
    showSubRetailerDetail(subRetailer) {
      this.selectSubRetailer(subRetailer);
      this.setShowSubRetailerSidebar(true);
    },
    async showSubRetailerUserSidebar(subRetailer) {
      this.selectSubRetailer(subRetailer);
      this.setShowSubRetailerUserSidebar(true);
      await this.getSubRetailerUsers();
    },
    showSubRetailerBalance(subRetailer) {
      this.selectSubRetailer(subRetailer);
      this.setShowSubRetailerBalanceSidebar(true);
    },
    notImplemented() {
      alert("not yet implemented.");
    },
  },
};
</script>

<style>
</style>