import axios from "axios";
import Session from './../session'

var instance = axios.create({
    baseURL: window.location.origin,
});

export default {
    namespaced: true,
    state: {
        verifying: false,
        admin: {
            id: '',
            name: '',
            email: '',
            password: ''
        },
    },
    getters: {
        admin(state) {
            return state.admin;
        }
    },
    mutations: {
        SET_Verifying(state, value) {
            state.verifying = value;
        },
        SET_Admin(state, value) {
            state.admin.id = value.id;
            state.admin.name = value.name;
            state.admin.email = value.email;
        }
    },
    actions: {
        ValidateAdminCredential({ commit, getters }) {
            commit('SET_Verifying', true);
            var admin = getters.admin;
            var token = btoa(`${admin.email}:${admin.password}`)

            return new Promise((resolve, reject) => {
                instance
                    .get("api/admin/token", {
                        headers: {
                            Authorization: "Basic " + token,
                        }
                    })
                    .then(async function (response) {
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        commit('SET_Verifying', false);
                        reject(error.response);
                    });
            });
        },
        GetAdminInfo({ commit }) {
            return new Promise((resolve, reject) => {
                instance
                    .get("api/admin", {
                        headers: {
                            Authorization: "Bearer " + Session.token(),
                        }
                    })
                    .then(async function (response) {
                        commit('SET_Admin', response.data);
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        }
    },
}