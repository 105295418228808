<template>
  <div class="row mx-1">
    <div class="col-6 align-items-center">
      <div class="row">
        <div class="col-4">
          <label>Category:</label>
          <select class="form-select" v-model="filter.category" @change="filterBiller">
            <option value="0">All</option>
            <option
              v-for="category in categories"
              :value="category.name"
              :key="category.name"
            >
              {{ category.name }}
            </option>
          </select>
        </div>
        <div class="col-8">
          <label>Filter:</label>
          <div class="input-group">
            <span class="input-group-text text-body">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Search Biller/s"
              onfocus="focused(this)"
              onfocusout="defocused(this)"
              v-model="filter.keyword"
              @keyup="filterBiller"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      categories: (state) => state.billerController.categories,
      filter: (state) => state.billerController.filter,
    }),
  },
  methods: {
    ...mapActions({
      getCategories: "billerController/GetCategories",
      getBillers: "billerController/GetBillers",
    }),
    ...mapMutations({
      filterBiller: "billerController/FILTER_Biller",
    })
  },
  async mounted() {
    await this.getCategories();
    await this.getBillers();
  },
};
</script>

<style>
</style>