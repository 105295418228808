<template>
  <div class="row mx-1">
    <div class="col-3 align-items-center">
      <label>Transaction Date:</label>
      <Datepicker
        v-model="filter.dateRange"
        :partial-range="false"
        :enable-time-picker="false"
        :clearable="false"
        :disable="loading"
        multi-calendars
        range
      />
    </div>
    <div class="col-3 d-flex align-items-end">
      <button
        class="btn bg-gradient-dark mb-0"
        :disable="loading"
        @click="getTransactions"
      >
        <i class="fas fa-search" v-if="!loading"></i>
        <i class="fa-solid fa-spinner fa-spin-pulse" v-if="loading"></i>
        Search
      </button>
    </div>
    <div class="col-6 d-flex align-items-end">
      <button
        class="btn bg-gradient-dark mb-0 ms-auto"
        :disable="exportLoading"
        @click="exportTransactions"
      >
        <i class="fas fa-download" v-if="!exportLoading"></i>
        <i class="fa-solid fa-spinner fa-spin-pulse" v-if="exportLoading"></i>
        Export
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      filter: (state) => state.retailerController.transactionsFilter,
      loading: (state) => state.retailerController.transactionsLoading,
      exportLoading: (state) =>
        state.retailerController.transactionsExportLoading,
    }),
  },
  methods: {
    ...mapActions({
      getTransactions: "retailerController/GetTransactions",
      exportTransactions: "retailerController/ExportTransactions",
    }),
  },
};
</script>

<style>
</style>