<template>
  <div class="row mx-1">
    <div class="col-6 align-items-center">
      <div class="row">
        <div class="col-4">
          <label>Category:</label>
          <select
            class="form-select"
            v-model="filter.category"
            @change="filterProducts"
          >
            <option value="0">All</option>
            <option value="REG">Regular Load</option>
            <option value="SP">Smart Prepaid</option>
            <option value="TNT">TNT</option>
            <option value="SBP">Smart Bro Prepaid</option>
            <option value="SBHW">Smart Bro Home Wifi</option>
            <option value="PLDT">PLDT</option>
            <option value="CIGNAL">Cignal</option>
            <option value="MERALCO">Meralco</option>
          </select>
        </div>
        <div class="col-8">
          <label>Filter:</label>
          <div class="input-group">
            <span class="input-group-text text-body">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Search Products"
              onfocus="focused(this)"
              onfocusout="defocused(this)"
              v-model="filter.searchKey"
              @keyup="filterProducts"
              :disabled="loading"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 d-flex align-items-end">
      <button
        class="btn bg-gradient-dark mb-0 ms-auto"
        :disabled="loading"
        @click="showSidebar = true"
      >
        <i class="fas fa-upload"></i>
        Upload ELP Products
      </button>
    </div>

    <sidebar
      title="Upload ELP Products"
      :show="showSidebar"
      @on-close="closeSidebar"
    >
      <template v-slot:body>
        <file-pond
          ref="pond"
          class-name="my-pond"
          :allowMultiple="true"
          :allowProcess="true"
          :required="true"
          :disabled="submitting"
          accepted-file-types="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :files="myFiles"
          @addfile="onAddFile"
          @removefile="onRemoveFile"
        />

        <div class="row" v-if="myFiles.length">
          <div class="col text-center">
            <button
              type="button"
              :disabled="submitting"
              class="btn bg-info bg-violet text-white w-40 mt-4 mb-0 mx-1"
              @click="upload"
            >
              <i
                v-if="submitting"
                class="fa-solid fa-spinner fa-spin-pulse mx-1"
              ></i>
              Upload now
            </button>
            <button
              type="button"
              :disabled="submitting"
              class="btn bg-secondary text-white w-40 mt-4 mb-0"
              @click="resetFiles"
            >
              Reset
            </button>
          </div>
        </div>
      </template>
    </sidebar>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { mapActions, mapMutations, mapState } from "vuex";
import sidebar from "../../../components/sidebar.vue";

// vue-filepond
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  components: {
    sidebar,
    FilePond,
  },
  data() {
    return {
      showSidebar: false,
      myFiles: [],
    };
  },
  computed: {
    ...mapState({
      filter: (state) => state.productController.filter,
      loading: (state) => state.productController.loading,
      submitting: (state) => state.productController.submitting,
    }),
  },
  methods: {
    ...mapActions({
      uploadProducts: "productController/UploadProducts",
      getProducts: "productController/GetProducts",
    }),
    ...mapMutations({
      filterProducts: "productController/FILTER_Products",
    }),
    closeSidebar() {
      this.showSidebar = false;
      this.resetFiles();
    },
    onAddFile(error, file) {
      if (error == null) {
        this.myFiles.push(file.file);
      }
    },
    onRemoveFile(error, file) {
      if (error == null) {
        const index = this.myFiles.indexOf(file.file);
        if (index > -1) {
          this.myFiles.splice(index, 1);
        }
      }
    },
    async upload() {
      var result = await this.uploadProducts(this.myFiles).catch((error) => {
        console.log(error);
      });

      if (result) {
        const toast = useToast();
        toast.success("ELP Products has been successfully uploaded.", {
          timeout: 2000,
        });

        this.closeSidebar();
        await this.getProducts();
      }
    },
    resetFiles() {
      this.$refs.pond.removeFiles();
    },
  },
};
</script>

<style>
</style>