import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Session from './store/session'
import TextFormat from './store/format'

import Vue3Lottie from 'vue3-lottie'
import Toast from "vue-toastification";
import VueAwesomePaginate from "vue-awesome-paginate";
import Datepicker from '@vuepic/vue-datepicker';

import '@vuepic/vue-datepicker/dist/main.css';
import "vue-toastification/dist/index.css";
import 'vue3-lottie/dist/style.css'
import "vue-awesome-paginate/dist/style.css";

const app = createApp(App)

app.use(store)
app.use(router)
app.use(Vue3Lottie)
app.use(Session)
app.use(Toast, options);
app.use(VueAwesomePaginate)

app.component('Datepicker', Datepicker)

app.config.globalProperties.$session = Session;
app.config.globalProperties.$format = TextFormat;

app.mount('#app')
