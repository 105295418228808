<template>
  <main class="main-content mt-0" v-if="token == null || token == ''">
    <section>
      <div class="page-header min-vh-85">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
              <div class="card card-plain mt-8">
                <div class="card-header pb-0 text-left bg-transparent">
                  <img src="/img/logo.jpg" class="navbar-brand-img w-100" />
                  <hr class="horizontal dark mt-0" />
                  <p class="mb-0">Enter your email and password to sign in.</p>
                </div>
                <div class="card-body">
                  <form role="form">
                    <label>Email</label>
                    <div class="mb-3">
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Email"
                        v-model="admin.email"
                        :disabled="verifying"
                        v-on:keyup.enter="validateLogin"
                      />
                    </div>
                    <label>Password</label>
                    <div class="mb-3">
                      <input
                        type="password"
                        class="form-control"
                        placeholder="Password"
                        v-model="admin.password"
                        :disabled="verifying"
                        v-on:keyup.enter="validateLogin"
                      />
                    </div>
                    <div
                      class="d-flex p-4 bg-gray-100 border-radius-lg"
                      v-if="invalidAccess"
                    >
                      <div class="d-flex flex-column">
                        <h6 class="text-sm text-danger">
                          <i class="fa-solid fa-triangle-exclamation mx-1"></i>
                          Invalid email and password.
                        </h6>
                      </div>
                    </div>
                    <div class="text-center">
                      <button
                        type="button"
                        class="btn bg-info bg-violet text-white w-100 mt-4 mb-0"
                        @click="validateLogin"
                        :disabled="verifying"
                      >
                        <i
                          class="fa-solid fa-spinner fa-spin-pulse mx-1"
                          v-if="verifying"
                        ></i>
                        Sign in
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <Vue3Lottie :animationData="loginJSON" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import loginJSON from "../assets/login.json";

export default {
  data() {
    return {
      loginJSON,
      invalidAccess: false,
    };
  },
  computed: {
    ...mapState({
      verifying: (state) => state.adminController.verifying,
      admin: (state) => state.adminController.admin,
    }),
    token() {
      return this.$session.token();
    },
  },
  methods: {
    ...mapActions({
      validateAdminCredential: "adminController/ValidateAdminCredential",
    }),
    async validateLogin() {
      this.invalidAccess = false;
      var result = await this.validateAdminCredential().catch((error) => {
        this.invalidAccess = true;
      });

      if (result) {
        this.$session.set(result.accessToken);
        window.location.href = "/retailers";
      }
    },
  },
  mounted() {
    if (this.token != null && this.token != "") {
      window.location.href = "/retailers";
    }
  },
};
</script>

<style>
</style>