<template>
  <div class="admin-content" v-if="token != null && token != ''">
    <sidebar />

    <main
      class="main-content position-relative h-100 border-radius-lg"
      style="min-height: 100vh; background: #f5f5f5"
    >
      <navbar />

      <div class="container-fluid py-4">
        <router-view />
      </div>
    </main>
  </div>
</template>

<script>
import sidebar from "./components/sidebar.vue";
import navbar from "./components/navbar.vue";
import { mapActions } from "vuex";

export default {
  components: {
    sidebar,
    navbar,
  },
  methods: {
    ...mapActions({
      getAdmin: "adminController/GetAdminInfo",
    }),
  },
  computed: {
    token() {
      return this.$session.token();
    },
  },
  async mounted() {
    if (this.token == null || this.token == "") {
      window.location.href = "/";
    } else {
      await this.getAdmin();
    }
  },
};
</script>
