import { createStore } from 'vuex'

import adminController from './controllers/adminController'
import productController from './controllers/productController'
import retailerController from './controllers/retailerController'
import billerController from './controllers/billerController'

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    adminController,
    productController,
    retailerController,
    billerController
  }
})
