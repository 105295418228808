import axios from "axios";
import Session from './../session'

var instance = axios.create({
    baseURL: window.location.origin,
});

export default {
    namespaced: true,
    state: {
        isLoading: true,
        categories: [],
        billers: [],
        filter: {
            category: "0",
            keyword: ""
        },
        filteredBiller: []
    },
    getters: {},
    mutations: {
        SET_IsLoading(state, value) {
            state.isLoading = value;
        },
        SET_Categories(state, value) {
            state.categories = value;
        },
        SET_Billers(state, value) {
            state.billers = value;
        },
        FILTER_Biller(state) {
            state.filteredBiller = Object.assign([], state.billers);

            if (state.filter.category != '0') {
                state.filteredBiller = state.filteredBiller.filter(m => m.category == state.filter.category);
            }

            if (state.filter.keyword != '') {
                state.filteredBiller = state.filteredBiller.filter(
                    m => m.name.toLowerCase().includes(state.filter.keyword.toLowerCase()) ||
                        m.code.toLowerCase().includes(state.filter.keyword.toLowerCase()) ||
                        m.description?.toLowerCase().includes(state.filter.keyword.toLowerCase()));
            }
        },
    },
    actions: {
        GetCategories({ commit }) {
            commit('SET_Categories', []);

            return new Promise((resolve, reject) => {
                instance
                    .get("api/biller/v2/categories", {
                        headers: {
                            Authorization: "Bearer " + Session.token()
                        }
                    })
                    .then(async function (response) {
                        commit('SET_Categories', response.data.categories);
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        GetBillers({ commit }) {
            commit('SET_Billers', []);
            commit('SET_IsLoading', true);

            return new Promise((resolve, reject) => {
                instance
                    .get("api/billers?category=", {
                        headers: {
                            Authorization: "Bearer " + Session.token()
                        }
                    })
                    .then(async function (response) {
                        commit('SET_Billers', response.data);
                        commit('FILTER_Biller');
                        commit('SET_IsLoading', false);
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        UpdateBillerStatus({ commit }, payload) {
            return new Promise((resolve, reject) => {
                instance
                    .put(`api/biller/${payload.code}/status?status=${payload.status}`, {
                        headers: {
                            Authorization: "Bearer " + Session.token()
                        }
                    })
                    .then(async function (response) {
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
    },
}