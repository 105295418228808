<template>
  <div class="card-body px-0 pt-0 pb-2">
    <div class="table-responsive p-0">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Category
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Code
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Name
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Description
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="7">
              <div class="text-center">
                <Vue3Lottie
                  :animationData="searchingJSON"
                  class="height-150 py-2"
                />
              </div>
            </td>
          </tr>
          <tr v-if="!isLoading && pageItems.length == 0">
            <td colspan="7">
              <div class="text-center">
                <Vue3Lottie
                  :animationData="noDataJSON"
                  class="height-150 py-2"
                />
                <p class="text-xs font-weight-bold">No Data Found</p>
              </div>
            </td>
          </tr>
          <tr v-for="(category, index) in pageItems" :key="index">
            <td>
              <p class="text-xs font-weight-bold mb-0">
                {{ category.category }}
              </p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">
                {{ category.code }}
              </p>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">
                <img
                  :src="category.logo"
                  style="width: 40px; margin-right: 20px"
                />
                {{ category.name }}
              </p>
            </td>
            <td class="align-middle text-sm">
              {{ category.description ? category.description : "-" }}
            </td>
            <td class="align-middle text-center">
              <label class="switch">
                <input
                  type="checkbox"
                  v-model="category.isActive"
                  @change="handleChange(category)"
                />
                <span class="slider round"></span>
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="paginate-div d-flex align-items-end mt-3 me-3">
      <vue-awesome-paginate
        class="ms-auto"
        :max-pages-shown="5"
        :total-items="billers.length"
        :items-per-page="itemPerPage"
        :on-click="onPageChanged"
        v-model="currentPage"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import noDataJSON from "./../../../assets/no-data.json";
import searchingJSON from "./../../../assets/searching.json";

export default {
  data() {
    return {
      noDataJSON,
      searchingJSON,
      itemPerPage: 20,
      pageItems: [],
      currentPage: 1,
    };
  },
  watch: {
    billers: {
      handler(newValue, oldValue) {
        this.onPageChanged(1);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.billerController.isLoading,
      billers: (state) => state.billerController.filteredBiller,
    }),
  },
  methods: {
    ...mapActions({
      updateBillerStatus: "billerController/UpdateBillerStatus",
    }),
    handleChange: async function (category) {
      category.isActive = !category.isActive;
      if (confirm("are you sure")) {
        var result = await this.updateBillerStatus({
          code: category.code,
          status: !category.isActive,
        });

        if (result) {
          category.isActive = !category.isActive;
        }
      }
    },
    onPageChanged(page) {
      //this.setCurrentPage(page);
      this.pageItems = this.billers.slice(
        (this.currentPage - 1) * this.itemPerPage,
        this.currentPage * this.itemPerPage
      );
    },
  },
};
</script>

<style scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>