<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-md-12 mb-lg-0 mb-4">
          <div class="card">
            <div class="card-header pb-0 p-3">
              <retailer-header />
            </div>
            <div class="card-body p-3">
              <retailer-list />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import header from "./header.vue";
import retailerList from "./retailerList.vue";

export default {
  components: {
    "retailer-header": header,
    "retailer-list": retailerList,
  },
};
</script>

<style>
</style>