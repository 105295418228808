<template>
  <sidebar title="Buy Load" :show="showLoadSubscriberSidebar" @on-close="close" size="sm">
    <template v-slot:body>
      <div class="target-accounts" v-show="progress == 0">

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>ELP Category</label>
              <div class="mb-3">
                <select class="form-select" v-model="loadSubscriber.category" :disabled="loadSubscriberSubmitting"
                  @change="filterProducts">
                  <option value="0" disabled>--- Select Category ---</option>
                  <option v-for="category in categories" :value="category.code" :key="category.code">
                    {{ category.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="loadSubscriber.category != 0">
          <div class="col">
            <div class="form-group">
              <label>ELP Products</label>
              <div class="mb-3">
                <select class="form-select" v-model="loadSubscriber.elpKeyword" :disabled="loadSubscriberSubmitting"
                  @change="onProductChanged">
                  <option value="0" disabled>--- Select Product ---</option>
                  <option v-for="product in filteredProducts" :value="product.keyword" :key="product.productID">
                    <b>{{ product.offer + " - " + product.description }}</b>
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="selectedProduct.family == 'FLEXIBLE LOAD'">
          <div class="col">
            <div class="form-group">
              <label>Amount</label>
              <div class="mb-3">
                <input type="number" class="form-control" :disabled="loadSubscriberSubmitting"
                  v-model="loadSubscriber.amount" @focus="$event.target.select()" />
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <button type="button" class="btn bg-info bg-violet text-white w-100 mt-2 mb-0" @click="validateNumbers">
            Proceed
          </button>
        </div>
      </div>
      <div class="elp-products" v-show="progress == 1">
        <div class="row">
          <div class="col">
            <div class="form-group mb-0">
              <label>Target Accounts</label>
              <Multiselect mode="tags" placeholder="Enter account number/s" :close-on-select="true" :searchable="true"
                :create-option="true" v-model="mobileNumbers" :options="options" @tag="addMobileNumber"
                @select="addMobileNumber" />
            </div>
          </div>
        </div>
        <div class="divider">
          <span>or</span>
        </div>
        <div class="row">
          <div class="col">
            <file-pond ref="pond" class-name="my-pond" :allowMultiple="false" :allowProcess="true" :required="true"
              :disabled="loadSubscriberSubmitting"
              accepted-file-types="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" :files="myFiles"
              @addfile="onAddFile" @removefile="onRemoveFile"
              labelIdle="Upload Account Numbers or <span class='filepond--label-action'> Browse" />
          </div>
        </div>
        <div class="text-center">
          <div class="row">
            <div class="col">
              <button type="button" class="btn bg-info bg-violet text-white w-100 mt-4 mb-0"
                :disabled="loadSubscriberSubmitting" @click="progress = 0">
                Back
              </button>
            </div>
            <div class="col">

              <button type="button" class="btn bg-info bg-violet text-white w-100 mt-4 mb-0"
                :disabled="loadSubscriberSubmitting" @click="submitSubRetailer">
                <i class="fa-solid fa-spinner fa-spin-pulse mx-1" v-if="loadSubscriberSubmitting"></i>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </sidebar>
</template>

<script>
import sidebar from "../../../../../components/sidebar.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import Multiselect from "@vueform/multiselect";
import * as XLSX from 'xlsx';

import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);
export default {
  components: {
    sidebar,
    Multiselect,
    FilePond,
  },
  data() {
    return {
      progress: 0,
      selectedProduct: 0,
      mobileNumbers: [],
      options: [],
      myFiles: [],
    };
  },
  computed: {
    ...mapState({
      filter: (state) => state.productController.filter,
      categories: (state) => state.productController.categories,
      filteredProducts: (state) => state.productController.filteredProducts,
      loadSubscriberSubmitting: (state) =>
        state.retailerController.loadSubscriberSubmitting,
      loadSubscriber: (state) => state.retailerController.loadSubscriber,
      showLoadSubscriberSidebar: (state) =>
        state.retailerController.showLoadSubscriberSidebar,
    }),
  },
  methods: {
    ...mapActions({
      directLoadSubscriber: "retailerController/DirectLoadSubscriber",
    }),
    ...mapMutations({
      toggleShowLoadSubscriberSidebar:
        "retailerController/TOGGLE_ShowLoadSubscriberSidebar",
      filterProducts: "productController/FILTER_Products",
    }),
    onCategoryChanged() {
      filter.category = this.loadSubscriber.category;
      this.filterProducts();
    },
    onProductChanged() {
      this.selectedProduct = this.filteredProducts.filter(
        (m) => m.keyword == this.loadSubscriber.elpKeyword
      )[0];
    },
    onAddFile(error, payload) {
      if (error == null) {
        this.myFiles.push(payload.file);

        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });

          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];

          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          var excelData = jsonData.slice(1);
          this.mobileNumbers = excelData
        };
        reader.readAsArrayBuffer(payload.file);
      }
    },
    onRemoveFile(error, file) {
      if (error == null) {
        const index = this.myFiles.indexOf(file.file);
        if (index > -1) {
          this.myFiles.splice(index, 1);
        }
      }
    },
    addMobileNumber(mobileNumber) {
      // console.log(mobileNumber);
      // console.log(this.mobileNumbers);
    },
    validateNumbers() {
      if (this.loadSubscriber.category == "0") {
        alert("Please select ELP Category.");
        return;
      }

      if (this.loadSubscriber.elpKeyword == "0") {
        alert("Please select ELP Products.");
        return;
      }

      if (
        this.selectedProduct.family == "FLEXIBLE LOAD" &&
        this.loadSubscriber.amount == "0"
      ) {
        alert("Please enter Amount.");
        return;
      }

      this.progress = 1
    },
    async submitSubRetailer() {
      this.loadSubscriber.mobileNumbers = this.mobileNumbers.join(",");

      if (this.loadSubscriber.mobileNumbers == "") {
        alert("Please enter Target Accounts.");
        return;
      }

      var result = await this.directLoadSubscriber().catch((error) => {
        alert(error.data.ErrorMessage);
      });

      if (result) {
        alert(`Target Account/s has been successfully loaded.`);
        this.close();
      }
    },
    close() {
      this.toggleShowLoadSubscriberSidebar(false);
      this.amount = 0;
    },
  },
};
</script>

<style>
.divider {
  background: #ddd;
  height: 2px;
  display: block;
  position: relative;
  width: 80%;
  margin: 15px auto;
}

.divider span {
  width: 30px;
  height: 20px;
  background: #fff;
  position: absolute;
  left: 50%;
  margin-left: -10px;
  top: -12px;
  text-align: center;
  color: #999;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>