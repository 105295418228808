<template>
  <div class="card-body px-0 pt-0 pb-2">
    <div class="table-responsive p-0">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              class="
                text-center text-uppercase text-secondary text-xxs
                font-weight-bolder
                opacity-7
              "
            >
              Category
            </th>
            <th
              class="
                text-center text-uppercase text-secondary text-xxs
                font-weight-bolder
                opacity-7
              "
            >
              Offer
            </th>
            <th
              class="
                text-center text-uppercase text-secondary text-xxs
                font-weight-bolder
                opacity-7
              "
            >
              ELP Keyword
            </th>
            <th
              class="
                text-center text-uppercase text-secondary text-xxs
                font-weight-bolder
                opacity-7
              "
            >
              SRP
            </th>
            <th
              class="
                text-center text-uppercase text-secondary text-xxs
                font-weight-bolder
                opacity-7
              "
            >
              Description
            </th>
            <th
              class="
                text-center text-uppercase text-secondary text-xxs
                font-weight-bolder
                opacity-7
              "
            >
              Validity
            </th>
            <th
              class="
                text-center text-uppercase text-secondary text-xxs
                font-weight-bolder
                opacity-7
              "
            >
              Date Updated
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading">
            <td colspan="7">
              <div class="text-center">
                <Vue3Lottie
                  :animationData="searchingJSON"
                  class="height-150 py-2"
                />
              </div>
            </td>
          </tr>
          <tr v-if="!loading && pageItems.length == 0">
            <td colspan="7">
              <div class="text-center">
                <Vue3Lottie
                  :animationData="noDataJSON"
                  class="height-150 py-2"
                />
                <p class="text-xs font-weight-bold">No Data Found</p>
              </div>
            </td>
          </tr>
          <tr v-for="(product, index) in pageItems" :key="index">
            <td>
              <p class="text-xs font-weight-bold mb-0">
                {{ product.category }}
              </p>
            </td>
            <td>
              <div class="d-flex px-2 py-1">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{ product.offer }}</h6>
                  <p class="text-xs text-secondary mb-0">
                    {{ product.family }}
                  </p>
                </div>
              </div>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">
                {{ product.keywordDisplay }}
              </p>
            </td>
            <td class="align-middle text-center text-sm">
              <span
                class="badge badge-sm bg-gradient-info"
                v-if="product.srp > 0"
              >
                Php {{ product.srp.toFixed(2) }}
              </span>
              <span
                class="badge badge-sm bg-gradient-info"
                v-if="product.srp == 0"
                >-</span
              >
            </td>
            <td class="align-middle">
              <span class="text-secondary text-xs font-weight-bold">
                {{ product.description }}
              </span>
            </td>
            <td class="align-middle">
              <span class="text-secondary text-xs font-weight-bold">
                {{ product.validity }} Days
              </span>
            </td>
            <td class="align-middle">
              <span class="text-secondary text-xs font-weight-bold">
                {{ $format.FormatFullDateTime(product.dateUpdated) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="paginate-div d-flex align-items-end mt-3 me-3">
      <vue-awesome-paginate
        class="ms-auto"
        :max-pages-shown="5"
        :total-items="products.length"
        :items-per-page="itemPerPage"
        :on-click="onPageChanged"
        v-model="currentPage"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import noDataJSON from "./../../../assets/no-data.json";
import searchingJSON from "./../../../assets/searching.json";

export default {
  data() {
    return {
      noDataJSON,
      searchingJSON,
      itemPerPage: 20,
      pageItems: [],
    };
  },
  computed: {
    ...mapState({
      currentPage: (state) => state.productController.currentPage,
      loading: (state) => state.productController.loading,
      products: (state) => state.productController.filteredProducts,
    }),
  },
  watch: {
    products: {
      handler(newValue, oldValue) {
        this.onPageChanged(1);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getProducts: "productController/GetProducts",
    }),
    ...mapMutations({
      setCurrentPage: "productController/SET_CurrentPage"
    }),
    onPageChanged(page) {
      this.setCurrentPage(page);
      this.pageItems = this.products.slice(
        (this.currentPage - 1) * this.itemPerPage,
        this.currentPage * this.itemPerPage
      );
    },
  },
  async mounted() {
    await this.getProducts();
  },
};
</script>
