<template>
  <sidebar
    :title="
      selectedRetailer.retailerID != null
        ? `Edit Retailer #${selectedRetailer.retailerID}`
        : 'Add Retailer'
    "
    :show="showRetailerSidebar"
    @on-close="close"
    size="sm"
  >
    <template v-slot:body>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Name</label>
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                v-model="selectedRetailer.name"
                :disabled="submitting"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Corporate ID</label>
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                v-model="selectedRetailer.corporateID"
                :disabled="submitting"
              />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Branch ID</label>
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                v-model="selectedRetailer.branchID"
                :disabled="submitting"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Username</label>
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                v-model="selectedRetailer.userName"
                :disabled="submitting"
              />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Password</label>
            <div class="mb-3">
              <input
                type="password"
                class="form-control"
                v-model="selectedRetailer.password"
                :disabled="submitting"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Secret Key</label>
            <div class="mb-3">
              <input
                type="password"
                class="form-control"
                v-model="selectedRetailer.secretKey"
                :disabled="submitting"
              />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Terminal ID</label>
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                v-model="selectedRetailer.terminalID"
                :disabled="submitting"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-9">
          <div class="form-group">
            <label>Address</label>
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                v-model="selectedRetailer.address"
                :disabled="submitting"
              />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Zip Code</label>
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                v-model="selectedRetailer.zipCode"
                :disabled="submitting"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="text-center">
        <button
          type="button"
          class="btn bg-info bg-violet text-white w-100 mt-4 mb-0"
          :disabled="submitting"
          @click="submitRetailer"
        >
          <i
            class="fa-solid fa-spinner fa-spin-pulse mx-1"
            v-if="submitting"
          ></i>
          Submit
        </button>
      </div>
    </template>
  </sidebar>
</template>

<script>
import sidebar from "../../../../../components/sidebar.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    sidebar,
  },
  computed: {
    ...mapState({
      submitting: (state) => state.retailerController.submitting,
      showRetailerSidebar: (state) =>
        state.retailerController.showRetailerSidebar,
      selectedRetailer: (state) => state.retailerController.selectedRetailer,
    }),
  },
  methods: {
    ...mapActions({
      getRetailers: "retailerController/GetRetailers",
      addRetailer: "retailerController/AddRetailer",
      updateRetailer: "retailerController/UpdateRetailer",
    }),
    ...mapMutations({
      toggleShowRetailerSidebar:
        "retailerController/TOGGLE_ShowRetailerSidebar",
      ResetRetailer: "retailerController/SELECT_Retailer",
    }),
    close() {
      this.toggleShowRetailerSidebar(false);
      this.ResetRetailer({});
    },
    async submitRetailer() {
      if (this.selectedRetailer.retailerID != null) {
        var result = await this.updateRetailer().catch((error) => {
          console.log(error);
        });

        if (result) {
          alert(
            `Retailer #${this.selectedRetailer.retailerID} successfully updated.`
          );
          await this.getRetailers();
          this.close();
        }
      } else {
        var result = await this.addRetailer().catch((error) => {
          console.log(error);
        });

        if (result) {
          alert("Retailer successfully added.");
          await this.getRetailers();
          this.close();
        }
      }
    },
  },
};
</script>

<style>
</style>