<template>
  <div class="row">
    <div class="col-2 pe-0">
      <h5>
        <a href="javascript:void(0)" @click="showSubRetailer(false)">
          <i class="fa-solid fa-arrow-left me-2"></i>
          {{ selectedRetailer.name }}
        </a>
      </h5>
    </div>
    <div class="col-2 px-0">
      <span class="mb-0 text-xs">
        Balance:
        <em>
          <strong>
            &#8369;
            {{ selectedRetailer.balance.toLocaleString("en") }}
          </strong>
        </em>
      </span>
    </div>
    <div class="col-2 px-0">
      <span class="mb-0 text-xs">
        Earmarked:
        <em>
          <strong>
            &#8369;
            {{ selectedRetailer.earmarked.toLocaleString("en") }}
          </strong>
        </em>
      </span>
    </div>
    <div class="col-6 d-flex align-items-end">
      <button
        class="btn bg-gradient-dark mb-0 ms-auto"
        @click="showLoadSubscriberSidebar"
      >
        <i class="fas fa-sim-card me-1"></i>
        Buy Load
      </button>
    </div>

    <load-subscriber-sidebar />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import loadSubscriberSidebar from "./sidebar/loadSubscriber.vue";

export default {
  components: {
    loadSubscriberSidebar,
  },
  computed: {
    ...mapState({
      selectedRetailer: (state) => state.retailerController.selectedRetailer,
    }),
  },
  methods: {
    ...mapMutations({
      showSubRetailer: "retailerController/SET_ShowSubRetailer",
      showLoadSubscriberSidebar:
        "retailerController/TOGGLE_ShowLoadSubscriberSidebar",
    }),
    ...mapActions({
      getProducts: "productController/GetProducts",
      getCategories: "productController/GetCategories",
    }),
  },
  async mounted() {
    await this.getCategories();
    await this.getProducts();
  },
};
</script>

<style>
</style>