import { createRouter, createWebHistory } from 'vue-router'
import loginPage from '../pages/login.vue'

import adminLayout from '../layouts/admin.vue'

import retailersView from '../views/retailers/index.vue'
import productsView from '../views/products/index.vue'
import billersView from '../views/billers/index.vue'
import transactionsView from '../views/transactions/index.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: loginPage
  },
  {
    path: '/',
    name: 'adminLayout',
    component: adminLayout,
    children: [
      {
        path: 'retailers',
        name: 'Retailers',
        component: retailersView,
      },
      {
        path: 'products',
        name: 'Products',
        component: productsView,
      },
      {
        path: 'billers',
        name: 'Billers',
        component: billersView,
      },
      {
        path: 'transactions',
        name: 'Transactions',
        component: transactionsView,
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
