<template>
  <sidebar
    title="Sub Retailer User Access"
    :show="showSubRetailerUserSidebar"
    @on-close="close"
    size="lg"
  >
    <template v-slot:body>
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col d-flex align-items-end">
              <button
                class="btn bg-gradient-dark mb-0 ms-auto"
                :disabled="subRetailerUsersLoading"
                @click="showSubRetailerUserDetail"
              >
                <i class="fas fa-plus me-1"></i>
                Add Sub Retailer User
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr class="horizontal dark" />
      <div class="row">
        <div class="col">
          <div class="table-responsive p-0">
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th
                    class="
                      text-uppercase text-secondary text-xxs
                      font-weight-bolder
                      opacity-7
                    "
                  >
                    Name
                  </th>
                  <th
                    class="
                      text-uppercase text-secondary text-xxs
                      font-weight-bolder
                      opacity-7
                      ps-2
                    "
                  >
                    Username
                  </th>
                  <th
                    class="
                      text-center text-uppercase text-secondary text-xxs
                      font-weight-bolder
                      opacity-7
                    "
                  >
                    Date Created
                  </th>
                  <th class="text-secondary opacity-7"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="subRetailerUsersLoading">
                  <td colspan="5">
                    <div class="text-center">
                      <Vue3Lottie
                        :animationData="searchingJSON"
                        class="height-150 py-2"
                      />
                    </div>
                  </td>
                </tr>
                <tr
                  v-if="
                    !subRetailerUsersLoading && subRetailerUsers.length == 0
                  "
                >
                  <td colspan="5">
                    <div class="text-center">
                      <Vue3Lottie
                        :animationData="noDataJSON"
                        class="height-150 py-2"
                      />
                      <p class="text-xs font-weight-bold">No Data Found</p>
                    </div>
                  </td>
                </tr>
                <tr v-for="(subRetailerUser, i) in subRetailerUsers" :key="i">
                  <td class="align-start">
                    <span class="text-secondary text-xs font-weight-bold">
                      {{ subRetailerUser.name }}
                    </span>
                  </td>
                  <td class="align-start">
                    <span class="text-secondary text-xs font-weight-bold">
                      {{ subRetailerUser.username }}
                    </span>
                  </td>
                  <td class="align-start">
                    <span class="text-secondary text-xs font-weight-bold">
                      {{
                        $format.FormatFullDateTime(subRetailerUser.timestamp)
                      }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <a
                      href="javascript:;"
                      class="text-secondary font-weight-bold text-xs"
                      data-toggle="tooltip"
                      data-original-title="Edit user"
                    >
                      <i
                        class="fas fa-trash-alt text-dark cursor-pointer mx-1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Delete"
                        @click="notImplemented"
                      ></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </sidebar>
</template>

<script>
import noDataJSON from "./../../../../../assets/no-data.json";
import searchingJSON from "./../../../../../assets/searching.json";
import sidebar from "../../../../../components/sidebar.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    sidebar,
  },
  data() {
    return {
      noDataJSON,
      searchingJSON,
    };
  },
  computed: {
    ...mapState({
      subRetailerUsers: (state) => state.retailerController.subRetailerUsers,
      subRetailerUsersLoading: (state) =>
        state.retailerController.subRetailerUsersLoading,
      showSubRetailerUserSidebar: (state) =>
        state.retailerController.showSubRetailerUserSidebar,
    }),
  },
  methods: {
    showSubRetailerUserDetail() {
      this.setShowSubRetailerUserDetailSidebar(true);
    },
    ...mapActions({
      getRetailers: "retailerController/GetRetailers",
      getSubRetailers: "retailerController/GetSubRetailers",
      updateSubRetailerBalance: "retailerController/UpdateSubRetailerBalance",
    }),
    ...mapMutations({
      setShowSubRetailerUserDetailSidebar:
        "retailerController/SET_ShowSubRetailerUserDetailSidebar",
      setShowSubRetailerUserSidebar:
        "retailerController/SET_ShowSubRetailerUserSidebar",
      selectSubRetailer: "retailerController/SELECT_SubRetailer",
    }),
    notImplemented() {
      alert("not yet implemented.");
    },
    close() {
      this.selectSubRetailer(null);
      this.setShowSubRetailerUserSidebar(false);
    },
    async submitSubRetailer() {
      var result = await this.updateSubRetailerBalance(this.amount).catch(
        (error) => {
          alert(error.data.ErrorMessage);
        }
      );
      if (result) {
        alert(
          `Sub Retailer #${this.selectedSubRetailer.subRetailerID} successfully loaded.`
        );
        await this.getRetailers();
        await this.getSubRetailers();
        this.close();
      }
    },
  },
};
</script>

<style>
</style>