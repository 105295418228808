import axios from "axios";
import Session from './../session'

var instance = axios.create({
    baseURL: window.location.origin,
});

export default {
    namespaced: true,
    state: {
        submitting: false,
        loading: false,
        categories: [],
        products: [],
        filteredProducts: [],
        currentPage: 1,
        filter: {
            category: "0",
            searchKey: ""
        }
    },
    getters: {},
    mutations: {
        SET_Submitting(state, value) {
            state.submitting = value;
        },
        SET_Loading(state, value) {
            state.loading = value;
        },
        SET_Categories(state, payload) {
            state.categories = payload;
            console.log(state.categories);
        },
        SET_Products(state, payload) {
            state.products = payload;
        },
        SET_CurrentPage(state, value) {
            state.currentPage = value;
        },
        FILTER_Products(state) {
            var searchKey = state.filter.searchKey;
            state.filteredProducts = state.products.filter(m => m.offer.toLowerCase().includes(searchKey.toLowerCase()) || m.family.toLowerCase().includes(searchKey.toLowerCase()) || m.keyword.toLowerCase().includes(searchKey.toLowerCase()));

            if (state.filter.category != '0') {
                state.filteredProducts = state.filteredProducts.filter(m => m.categoryCode == state.filter.category);
            }

            state.currentPage = 1;
        }
    },
    actions: {
        GetCategories({ commit }) {
            commit('SET_Categories', []);

            return new Promise((resolve, reject) => {
                instance
                    .get("api/misc/categories", {
                        headers: {
                            "x-public-key": "ZWxwQDIwMjI=",
                        }
                    })
                    .then(async function (response) {
                        commit('SET_Categories', response.data);
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        GetProducts({ commit }) {
            commit('SET_Loading', true);
            commit('SET_Products', []);
            commit('FILTER_Products');

            return new Promise((resolve, reject) => {
                instance
                    .get("api/product", {
                        headers: {
                            Authorization: "Bearer " + Session.token(),
                        }
                    })
                    .then(async function (response) {
                        commit('SET_Loading', false);
                        commit('SET_Products', response.data);
                        commit('FILTER_Products');
                        resolve(true);
                    })
                    .catch(function (error) {
                        commit('SET_Loading', false);
                        reject(error.response);
                    });
            });
        },
        UploadProducts({ commit }, files) {
            commit('SET_Submitting', true);
            let formData = new FormData();

            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                formData.append('files[' + i + ']', file);
            }

            return new Promise((resolve, reject) => {
                instance
                    .post("api/product/batch", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: "Bearer " + Session.token()
                        }
                    })
                    .then(async function (response) {
                        commit('SET_Submitting', false);
                        resolve(true);
                    })
                    .catch(function (error) {
                        commit('SET_Submitting', false);
                        reject(error.response);
                    });
            });
        }
    },
}