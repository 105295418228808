<template>
  <sidebar
    title="Sub Retailer User Detail"
    :show="showSubRetailerUserDetailSidebar"
    @on-close="close"
    size="sm"
  >
    <template v-slot:body>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Name</label>
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                v-model="selectedSubRetailerUser.name"
                :disabled="subRetailerUsersSubmitting"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Email</label>
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                v-model="selectedSubRetailerUser.email"
                :disabled="subRetailerUsersSubmitting"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Username</label>
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                v-model="selectedSubRetailerUser.username"
                :disabled="subRetailerUsersSubmitting"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button
          type="button"
          class="btn bg-info bg-violet text-white w-100 mt-4 mb-0"
          @click="submitSubRetailerUserAccess"
          :disabled="subRetailerUsersSubmitting"
        >
          <i
            class="fa-solid fa-spinner fa-spin-pulse mx-1"
            v-if="subRetailerUsersSubmitting"
          ></i>
          Submit
        </button>
      </div>
    </template>
  </sidebar>
</template>

<script>
import sidebar from "../../../../../components/sidebar.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    sidebar,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      selectedSubRetailer: (state) =>
        state.retailerController.selectedSubRetailer,
      selectedSubRetailerUser: (state) =>
        state.retailerController.selectedSubRetailerUser,
      subRetailerUsersSubmitting: (state) =>
        state.retailerController.subRetailerUsersSubmitting,
      showSubRetailerUserDetailSidebar: (state) =>
        state.retailerController.showSubRetailerUserDetailSidebar,
    }),
  },
  methods: {
    ...mapMutations({
      setShowSubRetailerUserDetailSidebar:
        "retailerController/SET_ShowSubRetailerUserDetailSidebar",
      selectSubRetailerUser: "retailerController/SELECT_SubRetailerUser",
    }),
    ...mapActions({
      getSubRetailerUsers: "retailerController/GetSubRetailerUsers",
      addSubRetailerUsers: "retailerController/AddSubRetailerUsers",
    }),
    close() {
      this.selectSubRetailerUser(null);
      this.setShowSubRetailerUserDetailSidebar(false);
    },
    async submitSubRetailerUserAccess() {
      var result = await this.addSubRetailerUsers().catch((error) => {
        alert(error.data.ErrorMessage);
      });
      if (result) {
        alert(
          `Sub Retailer user ${this.selectedSubRetailerUser.username} temporary password is ${result}.`
        );

        await this.getSubRetailerUsedrs();
        this.close();
      }
    },
  },
};
</script>

<style>
</style>