<template>
  <div class="fixed-plugin ps" :class="show ? 'show' : ''">
    <div class="overlay" @click.self="close">
      <div class="card shadow-lg overflow-auto" :class="'card-' + size">
        <div class="card-header pb-0 pt-3">
          <div class="float-start">
            <h5 class="mt-3 mb-0">{{ title }}</h5>
          </div>
          <div class="float-end mt-4">
            <button
              class="btn btn-link text-dark p-0 fixed-plugin-close-button"
              @click="close"
            >
              <i class="fa fa-close" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <hr class="horizontal dark my-1" />
        <div class="card-body pt-sm-3 pt-0">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    title: {
      type: String,
      required: true,
      default: "Sidebar",
    },
    size: {
      type: String,
      required: true,
      default: "md",
    },
  },
  methods: {
    close() {
      this.$emit("on-close");
    },
  },
};
</script>

<style>
</style>