<template>
  <div class="row">
    <div class="col" v-if="loading">
      <div class="text-center">
        <Vue3Lottie :animationData="searchingJSON" class="height-150 py-2" />
      </div>
    </div>
    <div class="col" v-if="!loading && retailers.length == 0">
      <div class="text-center">
        <Vue3Lottie :animationData="noDataJSON" class="height-150 py-2" />
        <p class="text-xs font-weight-bold">No Data Found</p>
      </div>
    </div>
    <div
      class="col-md-4 mb-md-0 mb-4 mt-2"
      v-for="(retailer, i) in retailers"
      :key="i"
    >
      <div
        class="card card-body border card-plain border-radius-lg"
        :class="retailer.elpRemarks != null ? 'card-disable' : ''"
      >
        <div class="row">
          <div class="col">
            <a href="javascript:void(0)" @click="loadSubRetailer(retailer)">
              <h6 class="mb-0">
                <b>{{ retailer.name }}</b>
                <i
                  class="fa-solid fa-arrow-right ms-2"
                  v-if="!subRetailerLoading"
                ></i>
                <i
                  class="fa-solid fa-spinner fa-spin-pulse mx-1"
                  v-if="subRetailerLoading"
                ></i>
              </h6>
            </a>
            <hr class="horizontal dark" />
            <p title="Branch ID" class="mb-0">{{ retailer.branchID }}</p>
            <p title="Corporate ID" class="mb-0">{{ retailer.corporateID }}</p>
            <p title="Username" class="mb-0">{{ retailer.userName }}</p>
            <p title="Address" class="mb-0" style="text-transform: capitalize">
              {{ retailer.address }}
            </p>
          </div>
          <div class="col text-right">
            <i
              @click="editRetailer(retailer)"
              v-if="!subRetailerLoading"
              class="fas fa-pencil-alt text-dark cursor-pointer"
              title="Edit Card"
            ></i>
            <h4 class="mb-0 mt-4">
              &#8369; {{ retailer.balance.toLocaleString("en") }}
            </h4>
            <h5 class="mb-0 text-xs">
              Earmarked:
              <em>&#8369; {{ retailer.earmarked.toLocaleString("en") }}</em>
            </h5>
            <p class="mb-0 mt-4 text-danger">
              <em>{{ retailer.elpRemarks }}</em>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import noDataJSON from "./../../../../assets/no-data.json";
import searchingJSON from "./../../../../assets/searching.json";

export default {
  data() {
    return {
      noDataJSON,
      searchingJSON,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.retailerController.loading,
      retailers: (state) => state.retailerController.filteredRetailers,
      subRetailerLoading: (state) =>
        state.retailerController.subRetailerLoading,
    }),
  },
  methods: {
    ...mapActions({
      getRetailers: "retailerController/GetRetailers",
      getSubRetailers: "retailerController/GetSubRetailers",
    }),
    ...mapMutations({
      selectRetailer: "retailerController/SELECT_Retailer",
      toggleShowRetailerSidebar:
        "retailerController/TOGGLE_ShowRetailerSidebar",
    }),
    async loadSubRetailer(retailer) {
      this.selectRetailer(retailer);
      await this.getSubRetailers();
    },
    editRetailer(retailer) {
      this.selectRetailer(retailer);
      this.toggleShowRetailerSidebar(true);
    },
  },
  async mounted() {
    await this.getRetailers();
  },
};
</script>

<style>
</style>