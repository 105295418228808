<template>
  <sidebar
    title="Top Up Sub Retailer Balance"
    :show="showSubRetailerBalanceSidebar"
    @on-close="close"
    size="sm"
  >
    <template v-slot:body>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Amount</label>
            <div class="mb-3">
              <input
                type="number"
                class="form-control"
                :disabled="subRetailerSubmitting"
                v-model="amount"
                @focus="$event.target.select()"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="text-center">
        <button
          type="button"
          class="btn bg-info bg-violet text-white w-100 mt-4 mb-0"
          @click="submitSubRetailer"
        >
          <i
            class="fa-solid fa-spinner fa-spin-pulse mx-1"
            v-if="subRetailerSubmitting"
          ></i>
          Submit
        </button>
      </div>
    </template>
  </sidebar>
</template>

<script>
import sidebar from "../../../../../components/sidebar.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    sidebar,
  },
  data() {
    return {
      amount: 0,
    };
  },
  computed: {
    ...mapState({
      showSubRetailerBalanceSidebar: (state) =>
        state.retailerController.showSubRetailerBalanceSidebar,
      subRetailerSubmitting: (state) =>
        state.retailerController.subRetailerSubmitting,
      selectedSubRetailer: (state) =>
        state.retailerController.selectedSubRetailer,
    }),
  },
  methods: {
    ...mapActions({
      getRetailers: "retailerController/GetRetailers",
      getSubRetailers: "retailerController/GetSubRetailers",
      updateSubRetailerBalance: "retailerController/UpdateSubRetailerBalance",
    }),
    ...mapMutations({
      setShowSubRetailerBalanceSidebar:
        "retailerController/SET_ShowSubRetailerBalanceSidebar",
      selectSubRetailer: "retailerController/SELECT_SubRetailer",
    }),
    close() {
      this.selectSubRetailer(null);
      this.setShowSubRetailerBalanceSidebar(false);
      this.amount = 0;
    },
    async submitSubRetailer() {
      var result = await this.updateSubRetailerBalance(this.amount).catch(
        (error) => {
          alert(error.data.ErrorMessage);
        }
      );
      if (result) {
        alert(
          `Sub Retailer #${this.selectedSubRetailer.subRetailerID} successfully loaded.`
        );
        await this.getRetailers();
        await this.getSubRetailers();
        this.close();
      }
    },
  },
};
</script>

<style>
</style>