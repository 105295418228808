<template>
  <div class="sub-retailer-header">
    <h6>Sub Retailers</h6>
    <div class="row">
      <div class="col-6 align-items-center">
        <div class="row">
          <div class="col">
            <div class="input-group">
              <span class="input-group-text text-body">
                <i class="fas fa-search" aria-hidden="true"></i>
              </span>
              <input
                type="text"
                class="form-control"
                placeholder="Search Sub Retailer"
                onfocus="focused(this)"
                onfocusout="defocused(this)"
                v-model="filter.keyword"
                @keyup="filterSubRetailers"
                :disabled="loading"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 d-flex align-items-end">
        <button
          class="btn bg-gradient-dark mb-0 ms-auto"
          @click="showSubRetailerDetail"
        >
          <i class="fas fa-plus me-1"></i>
          Add Sub Retailer
        </button>
      </div>
    </div>

    <sub-retailer-users />
    <sub-retailer-user-detail />
    <sub-retailer-detail />
    <sub-retailer-balance />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import subRetailerBalance from './sidebar/subRetailerBalance.vue';
import subRetailerDetail from "./sidebar/subRetailerDetail.vue";
import subRetailerUsers from "./sidebar/subRetailerUsers.vue";
import subRetailerUserDetail from "./sidebar/subRetailerUserDetail.vue";

export default {
  components: {
    subRetailerDetail,
    subRetailerBalance,
    subRetailerUsers,
    subRetailerUserDetail
  },
  computed: {
    ...mapState({
      filter: (state) => state.retailerController.subRetailerFilter,
    }),
  },
  methods: {
    ...mapMutations({
      setShowSubRetailerSidebar:
        "retailerController/SET_ShowSubRetailerSidebar",
      selectSubRetailer: "retailerController/SELECT_SubRetailer",
      filterSubRetailers: "retailerController/FILTER_SubRetailers"
    }),
    showSubRetailerDetail() {
      this.selectSubRetailer(null);
      this.setShowSubRetailerSidebar(true);
    },
  },
};
</script>

<style>
</style>